import { Money, Image } from 'core/components'
import { SellOffer } from 'model'
import Link from 'next/link'
import { ButtonCard, Stack, Text } from 'ui/core'

interface SellOfferCardProps {
  sellOffer: SellOffer,
}

const SellOfferCard = ({ sellOffer }: SellOfferCardProps) => (
  <ButtonCard component={Link} href={`/sell/${sellOffer.product.slug}`}>
    <Stack gap="xs" w="100%" align="center">
      <Image
        width={200}
        height={200}
        src={sellOffer.product?.image}
        alt={sellOffer.product.title || ''}
      />
      <Text ta="center" lineClamp={1}>
        {sellOffer.product?.title}
      </Text>
      <Text align="center">
        Get up to{' '}
        <Money
          inline
          component="span"
          size="lg"
          fw="bold"
          value={sellOffer.price}
        />
      </Text>
    </Stack>
  </ButtonCard>
)

export default SellOfferCard
