import { Money } from 'core/components'
import { SwapOffer } from 'model'
import Link from 'next/link'
import { Box, Group, Icon, Stack, Text, ButtonCard } from 'ui/core'
import { SwapOfferProduct } from '../SwapOfferProduct'

interface SwapOfferCardProps {
  swapOffer: SwapOffer,
}

const SwapOfferCard = ({ swapOffer }: SwapOfferCardProps) => (
  <ButtonCard
    component={Link}
    href={`/swap/${swapOffer.products[0].slug}?for=${swapOffer.products[1].slug}`}
  >
    <Stack gap="xs" w="100%" align="center">
      <Group align="center" wrap="nowrap" gap={2}>
        <SwapOfferProduct product={swapOffer.products[0]} />
        <Box lh={0} style={{ flexShrink: 0 }}>
          <Icon name="repeat" size={28} />
        </Box>
        <SwapOfferProduct product={swapOffer.products[1]} />
      </Group>
      <Text align="center">
        Add up to{' '}
        <Money
          inline
          component="span"
          size="lg"
          fw="bold"
          value={swapOffer.price}
        />
      </Text>
    </Stack>
  </ButtonCard>
)

export default SwapOfferCard
