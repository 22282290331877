import { useQueryNotifications } from 'lib/notification'
import { Tumbleweed } from 'core/components'
import { NotificationCard, NotificationCardLoading, SectionTitle } from 'components'
import { Stack } from 'ui/core'

const Notifications = () => {
  const { data, loading } = useQueryNotifications()

  return (
    <div>
      <SectionTitle title="Notifications" />
      {loading && (
        <Stack>
          {[...new Array(2).keys()].map((i) => (
            <NotificationCardLoading key={i} />
          ))}
        </Stack>
      )}
      {!loading && data?.length ? (
        <Stack>
          {data?.slice(0, 2).map((notification) => (
            <NotificationCard key={notification.id} notification={notification} />
          ))}
        </Stack>
      ) : null}
      {!loading && !data?.length ? (
        <Tumbleweed variant="card" tumbleweedContent="notifications" />
      ) : null}
    </div>
  )
}

export default Notifications
